import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Icons from "../../core/ui/images/icomoon/symbol-defs.svg";
import Footer from "../../core/ui/organisms/footer/Footer";
import Meta from "../../organisms/meta/meta";
import Navigation from "../../organisms/navigation/Navigation";
import PageWrapper from "../../organisms/page-wrapper/PageWrapper";
import { availableLocales } from "../../settings/countries";
import useAxeptioScriptLoader from "../../utils/hooks/useAxeptioScriptLoader";
import { getCountryByLocale } from "../../utils/locale-configuration-utils";

const LegalText = ({ data }) => {
  const { title, slug, locale, content, footer } = data.contentfulLegalText;
  const alternateVersions = data.alternateVersions.nodes.filter((item) =>
    availableLocales.includes(item.locale)
  );

  const countryCodeIso = getCountryByLocale(locale);

  const { i18n } = useI18next();
  void i18n.changeLanguage(locale);
  useAxeptioScriptLoader(locale);

  return (
    <>
      <Meta
        title={title}
        description={title}
        slug={slug}
        alternateVersions={alternateVersions}
        lang={locale}
        bodyClass={"tp-legaltext"}
      />
      <PageWrapper>
        <Navigation countryCodeIso={countryCodeIso} />
        <main id="main" className="or-main">
          <h1>{title}</h1>
          {content && <div className="content">{renderRichText(content)}</div>}
        </main>
        {footer && <Footer data={footer} countryCode={countryCodeIso} />}
        <Icons />
      </PageWrapper>
    </>
  );
};

export default LegalText;

export const query = graphql`
  query LegalTextQuery($id: String, $internalSlug: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulLegalText(id: { eq: $id }) {
      title
      locale: node_locale
      content {
        raw
      }
      slug
      internalSlug
      footer {
        ...FooterFragment
      }
    }
    alternateVersions: allContentfulLegalText(
      filter: { internalSlug: { eq: $internalSlug }, slug: { ne: null } }
    ) {
      nodes {
        id
        slug
        locale: node_locale
      }
    }
  }
`;
